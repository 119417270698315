import axios from "axios";

export const customFetch = axios.create({
  baseURL:
    "https://backend.heritageafrica.com",
    
  headers: {
    "Content-type": "application/json",
  },
});


customFetch.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
