import { useEffect, useState } from "react";
import "./style.css";
import { useInfo } from "../../context";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { checkoutSession } from "../../services/bookingSevice";
import CheckoutForm from "../CheckoutForm";

export const SplitPaymentForm = ({ selectedPaymentMethod, isBuyNowPayLaterSelected}) => {
  const { state, updateCurrentStep } = useInfo();
  const [clientSecret, setClientSecret] = useState();

  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get("email");
  const tripDate = urlParams.get("trip_date");
  const cost = urlParams.get("cost");

  const stripePromise = loadStripe(
    "pk_live_lUSVarvS3pGTiFLo8sQe25Hp000fJIzNvD"
  );

  const fetchClientSecret = async () => {
    try {
      const checkoutBody = {email: userEmail, cost: cost}
      const checkoutSessionData = await checkoutSession(
        checkoutBody,
        selectedPaymentMethod
      );

      const client_secret = checkoutSessionData.clientSecret;

      setClientSecret(client_secret);

      return client_secret;
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
    clientSecret,
    appearance,
  };

  return (
    <div id="checkout">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
};
