export const transformArray = (array) => {
  const transformedArray = array.reduce((acc, option) => {
    acc[option.name] = option.quantity;
    acc[option.prices] = option.price;
    return acc;
  }, {});

  return transformedArray;
};
export const transformOptionArray = (array) => {
const transformedArray = array.reduce((acc, option) => {
  acc[option.name] = option.quantity;
  return acc;
}, {});

return transformedArray;
};
