import { customFetch } from "../api/axios";

export const getTravelInfo = async (title) => {
  try {
    const response = await customFetch.get(`/trips/${title}`);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const getBookingTravelInfo = async (title) => {
  try {
    const response = await customFetch.get(`/bookings/?trip_id=${title}`);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const checkoutSession = async (body, selectedPaymentMethod) => {
  try {
    const response = await customFetch.post(`/create-payment/?payment_method=${selectedPaymentMethod}`, body);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const bookings = async (body) => {
  try {
    const response = await customFetch.post("/bookings/", body);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const clients = async (body) => {
  try {
    const response = await customFetch.post("/clients/", body);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const createSubscription = async (body, selectedPaymentMethod, payment_method_id,  period_duration="week") => {
  try {
    const response = await customFetch.post(`/create-subscription/?payment_method=${selectedPaymentMethod}&payment_method_id=${payment_method_id}&period_duration=${period_duration}`, body);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};
export const getAllTrips = async () => {
  try {
    const response = await customFetch.get(`/all-trips/`);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const getImageFields = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(
      "https://backend.heritageafrica.com/process-image/",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to process image");
    }

    const responseData = await response.json();
    return responseData.body;
  } catch (error) {
    console.error(error);
  }
};

export const updateBookingEmail = async (body) => {
  try {
    const response = await customFetch.post("/update-booking-emails/", body);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const uploadImage = async (url, file) => {
  const formData = new FormData();
  formData.append("photo", file);

  const secondApiResponse = await fetch(url, {
    method: "PUT",
    headers: {
      // "Content-Type": "application/json",
      // "Content-Type": "image/png"
      // "Content-Type":"multipart/form-data; boundary=—-WebKitFormBoundaryfgtsKTYLsT7PNUVD"
    },
    body: file,
  });

  if (!secondApiResponse.ok) {
    throw new Error("Failed to fetch data from the second API");
  }
};

export const getPromocode = async (promocode_id) => {
  try {
    const response = await customFetch.get(`/get-promocode/${promocode_id}`);
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const getBookings = async (bookingID) => {
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer eyJraWQiOiJcL05TOXRTQ1FQTjA1R01LRUhxQmpmWHlkNVwvSXdqa3AwOFBsMlYxZzUrUzA9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI4Mzk0NDgwMi0xMDgxLTcwMzUtNTE5Ny0xY2Y4NDJlOTQ0MzciLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV8wVVdIQTJJb2kiLCJjbGllbnRfaWQiOiIzam5hbXZ0YWNkZjQ5ZTM2OXAxMGZhbDljNCIsIm9yaWdpbl9qdGkiOiIxMmI4MDQ2NC01NWFhLTQ2NzAtYWM1ZS1mN2NiOWY3NjM5YjkiLCJldmVudF9pZCI6IjE2ZjVhZDdlLTU3MWUtNDc5MC1hNTJjLWU0NzBkNzJiNmUzMyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MTU0MTY0MDUsImV4cCI6MTcxNTQyMDAwNSwiaWF0IjoxNzE1NDE2NDA1LCJqdGkiOiI2YjdiZDE3MC0yMzBkLTQwYTAtYTJhZi0zNDQ4MWQ1M2E0YzUiLCJ1c2VybmFtZSI6ImFkbWluMSJ9.A_YmKIjQdmble5QxSYGtcwallPrIV4IOTHlzkVZDitVgH42BjAwjSvApkeBhbKXeoQ9G60SBvFEaS8VHmEkquTZbdQk2Fo1dQU1hc8weOln1N8rddCHt90R8SOS1LmDlJdgfZeEs4GPeegik1vcGazHWOJgxE077wY1OJ-qSDLjF2r7I_SG2r2WKDTSSIMf8CXY3bCoPHr-68BZdJfTukoVPHNifLhTy9dM4f8Zhisc-hcR-B5bnPk-K6_9QbU4RStoX61_woNcxr0NrD-8QHXtXDgOTBu9xbQeT1WKky09cYTJqMCtPqIpNvH090rxE6UvgEnFsPQdffeMoCZ5XVw`,
    },
  };
  try {
    const response = await customFetch.get(
      `/bookings/?trip_id=${bookingID}`,
      config
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const updateClient = async (client_id, body) => {
  const token = JSON.parse(localStorage.getItem("tokens"))?.access_token;
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer eyJraWQiOiJcL05TOXRTQ1FQTjA1R01LRUhxQmpmWHlkNVwvSXdqa3AwOFBsMlYxZzUrUzA9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI4Mzk0NDgwMi0xMDgxLTcwMzUtNTE5Ny0xY2Y4NDJlOTQ0MzciLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV8wVVdIQTJJb2kiLCJjbGllbnRfaWQiOiIzam5hbXZ0YWNkZjQ5ZTM2OXAxMGZhbDljNCIsIm9yaWdpbl9qdGkiOiIxMmI4MDQ2NC01NWFhLTQ2NzAtYWM1ZS1mN2NiOWY3NjM5YjkiLCJldmVudF9pZCI6IjE2ZjVhZDdlLTU3MWUtNDc5MC1hNTJjLWU0NzBkNzJiNmUzMyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MTU0MTY0MDUsImV4cCI6MTcxNTQyMDAwNSwiaWF0IjoxNzE1NDE2NDA1LCJqdGkiOiI2YjdiZDE3MC0yMzBkLTQwYTAtYTJhZi0zNDQ4MWQ1M2E0YzUiLCJ1c2VybmFtZSI6ImFkbWluMSJ9.A_YmKIjQdmble5QxSYGtcwallPrIV4IOTHlzkVZDitVgH42BjAwjSvApkeBhbKXeoQ9G60SBvFEaS8VHmEkquTZbdQk2Fo1dQU1hc8weOln1N8rddCHt90R8SOS1LmDlJdgfZeEs4GPeegik1vcGazHWOJgxE077wY1OJ-qSDLjF2r7I_SG2r2WKDTSSIMf8CXY3bCoPHr-68BZdJfTukoVPHNifLhTy9dM4f8Zhisc-hcR-B5bnPk-K6_9QbU4RStoX61_woNcxr0NrD-8QHXtXDgOTBu9xbQeT1WKky09cYTJqMCtPqIpNvH090rxE6UvgEnFsPQdffeMoCZ5XVw`,
    },
  };
  try {
    const response = await customFetch.put(
      `/update-client/${client_id}`,
      body,
      config
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const updateBooking = async (booking_id, body, token) => {
  // const token = JSON.parse(localStorage.getItem("tokens"))?.access_token;
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    //   Authorization: `Bearer eyJraWQiOiJcL05TOXRTQ1FQTjA1R01LRUhxQmpmWHlkNVwvSXdqa3AwOFBsMlYxZzUrUzA9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI4Mzk0NDgwMi0xMDgxLTcwMzUtNTE5Ny0xY2Y4NDJlOTQ0MzciLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV8wVVdIQTJJb2kiLCJjbGllbnRfaWQiOiIzam5hbXZ0YWNkZjQ5ZTM2OXAxMGZhbDljNCIsIm9yaWdpbl9qdGkiOiIxMmI4MDQ2NC01NWFhLTQ2NzAtYWM1ZS1mN2NiOWY3NjM5YjkiLCJldmVudF9pZCI6IjE2ZjVhZDdlLTU3MWUtNDc5MC1hNTJjLWU0NzBkNzJiNmUzMyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MTU0MTY0MDUsImV4cCI6MTcxNTQyMDAwNSwiaWF0IjoxNzE1NDE2NDA1LCJqdGkiOiI2YjdiZDE3MC0yMzBkLTQwYTAtYTJhZi0zNDQ4MWQ1M2E0YzUiLCJ1c2VybmFtZSI6ImFkbWluMSJ9.A_YmKIjQdmble5QxSYGtcwallPrIV4IOTHlzkVZDitVgH42BjAwjSvApkeBhbKXeoQ9G60SBvFEaS8VHmEkquTZbdQk2Fo1dQU1hc8weOln1N8rddCHt90R8SOS1LmDlJdgfZeEs4GPeegik1vcGazHWOJgxE077wY1OJ-qSDLjF2r7I_SG2r2WKDTSSIMf8CXY3bCoPHr-68BZdJfTukoVPHNifLhTy9dM4f8Zhisc-hcR-B5bnPk-K6_9QbU4RStoX61_woNcxr0NrD-8QHXtXDgOTBu9xbQeT1WKky09cYTJqMCtPqIpNvH090rxE6UvgEnFsPQdffeMoCZ5XVw`,
    },
  };
  try {
    const response = await customFetch.put(
      `/update-booking/${booking_id}`,
      body,
      config
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};
