import { useEffect, useState } from "react";
import "./style.css";
import { useInfo } from "../../context";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { checkoutSession } from "../../services/bookingSevice";
import CheckoutForm from "../CheckoutForm";
import { checkStartDate } from "../../globals/checkDateType";
import { formatOneDate } from "../../globals/formateDate";

export const UpdateInfoPaymentForm = () => {
  const { state, updateCurrentStep } = useInfo();
  const [clientSecret, setClientSecret] = useState();

  const stripePromise = loadStripe(
    "pk_live_lUSVarvS3pGTiFLo8sQe25Hp000fJIzNvD"
  );
  const totalQuantity = state.packages.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const totalPackagesResult = state.packages.reduce(
    (total, pkg) => total + pkg.result,
    0
  );
  const totalOptionsResult = state.options.reduce(
    (total, opt) => total + opt.result,
    0
  );
  let totalResult = totalPackagesResult + totalOptionsResult;

  if (state.promocode.percent_off !== null) {
    const discount = totalResult * (state.promocode.percent_off / 100);
    totalResult -= discount;
  }

  const packagesQuantity = state.packages
    .map((item) => {
      if (item.quantity) {
        return `${item.name} * ${item.quantity}`;
      } else {
        return item.name;
      }
    })
    .join(", ");

  const fetchClientSecret = async () => {
    try {
      const checkoutSessionData = await checkoutSession({
        email: state.booking.client_data[0].email,
        cost: 30,
      });

      const client_secret = checkoutSessionData.clientSecret;

      setClientSecret(client_secret);

      return client_secret;
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
    clientSecret,
    appearance,
  };

  return (
    <div id="checkout">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
};
