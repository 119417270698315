import { SplitPaymentModalHeader } from "../../components/SplitPaymentModalHeader";
import { SplitPaymentDataForm } from "../../components/SplitPaymentDataForm";
import { SplitPaymentCheckoutInfo } from "../../components/SplitPaymentCheckoutInfo";
export const SplitPaymentModal = () => {
  return (
    <div className="modal_wrapper">
      <SplitPaymentModalHeader />
      <div style={{ display: "flex" }}>
        <SplitPaymentDataForm />
        <SplitPaymentCheckoutInfo />
      </div>
    </div>
  );
};
