import { customFetch } from "../api/axios";

export const getHotelResources = async () => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    const response = await customFetch.get(
      '/hotels/',
      config
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};

export const getAirlinesResources = async () => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    const response = await customFetch.get(
      '/flights/',
      config
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {}
};
