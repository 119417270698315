import { DataForm } from "../../components/DataForm";
import { ModalHeader } from "../../components/ModalHeader";
import { CheckoutInfo } from "../../components/CheckoutInfo";

import "./style.css";

export const RegistrationModal = () => {
  return (
    <div className="modal_wrapper">
      <ModalHeader />
      <div style={{ display: "flex" }}>
        <DataForm />
        <CheckoutInfo />
      </div>
    </div>
  );
};
