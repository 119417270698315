import { useEffect } from "react";
import "./style.css";
import initializeGTM from "../../Gtm";
export const BookingConfirm = () => {
  useEffect(() => {
    initializeGTM();
  }, []);
  return (
    <div className="booking_confirm_page">
      <div className="booking_confirm_title">
        <a href="https://heritageafrica.com/">
          <img
            src="https://heritageafrica.com/wp-content/uploads/2022/06/logo.png"
            alt="logo"
          />
        </a>
      </div>
      <div className="main_part_wrapper">
        <h1 style={{ marginTop: "20vh" }}>Thank you</h1>
        <h1>for confirming your booking!</h1>
        <h5> We're excited to have you join us.</h5>
      </div>
    </div>
  );
};
