import { useEffect, useState } from "react";
import { getAllTrips } from "../../services/bookingSevice";
import "./style.css";
import { useNavigate } from "react-router-dom";

export const Trips = () => {
  const navigate = useNavigate();
  const [trips, setTrips] = useState(null);
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const trips = await getAllTrips();
        setTrips(trips);
      } catch (error) {
        console.error("Failed to fetch travel info:", error);
      }
    };
    fetchTrips();
  }, []);
  const handleClick = (tripID) => {
    navigate(`/booking-trip/${tripID}`);
  };
  return (
    <div className="trips_wrapper">
      <div className="trips_wrapper">
        {trips?.map((trip,i) => {
          return (
            <button onClick={() => handleClick(trip.trip_id)} key={i}>
              {trip.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};
