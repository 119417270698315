import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SplitPaymentModalHeader } from "../../components/SplitPaymentModalHeader";
import { SplitPaymentDataForm } from "../../components/SplitPaymentDataForm";
import { SplitPaymentCheckoutInfo } from "../../components/SplitPaymentCheckoutInfo";
import { ModalHeader } from "../../components/ModalHeader";
import { UpdateInfoDataForm } from "../../components/UpdateInfoDataForm";
import { UpdateInfoCheckoutInfo } from "../../components/UpdateInfoCheckoutInfo";

export const UpdateInformationModal = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [newPrice, setNewPrice] = useState(49)

  let type = searchParams.get("type")
  return (
    <div className="modal_wrapper">
      <ModalHeader />
      <div style={{ display: "flex" }}>
        <UpdateInfoDataForm type={type} newPrice={newPrice} setNewPrice={setNewPrice} />
        <UpdateInfoCheckoutInfo type={type} newPrice={newPrice}/>
      </div>
    </div>
  );
};
