import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useInfo } from "../../context";
import "./style.css";
import { objectToArray } from "../../globals/objectToArray";
import { formatKey } from "../../globals/formatKey";
import { bookings } from "../../services/bookingSevice";
import {
  transformArray,
  transformOptionArray,
} from "../../globals/transformArrays";
import { PaymentOptions } from "../PaymentOptions";
import {
  getDatesWithSameDayOfWeek,
  getDatesEvery15Days,
} from "../../globals/getDayOfWeekBeforeDate";

export const ChoosePachageForm = ({setSelectedPaymentMethod, setIsBuyNowPayLaterSelected}) => {
  const {
    updateBuyerInfo,
    updatePackage,
    updateCurrentStep,
    updateOptions,
    state,
    updateBookingID,
    updateHotelInfo,
    updateBookingCost,
    updateTripDate,
    updateSplitStatus
  } = useInfo();

  const [isPackageSelected, setIsPackageSelected] = useState(true);
  const [isFieldsFull, setIsFieldsFull] = useState(true);
  const [defaultPackageSelected, setDefaultPackageSelected] = useState(false);
  const query = new URLSearchParams(useLocation().search); // Extract query parameters
  const selectedTripDate = query.get('tripdate'); // Get the value of 'selectedTripDate'
  const selectedPackage = query.get('selectedpackage')

  useEffect(() => {
    // console.log(selectedTripDate)
    if (selectedTripDate){
      updateTripDate(selectedTripDate)
    }
  }, [selectedTripDate]);


  useEffect(() => {
    // console.log(selectedPackage)
    // console.log('state.travelInfo?.package', state.travelInfo?.package)
    if (Boolean(!defaultPackageSelected && selectedPackage && state.travelInfo?.package[selectedPackage.toLowerCase()])){
      // updateTripDate(selectedTripDate)
      const paramsPackageDetails = state.travelInfo?.package[selectedPackage.toLowerCase()]
      console.log(paramsPackageDetails)

      // console.log('selectedIndex', selectedIndex)

      const replacedName = selectedPackage.replace(/_/g, ' ');
      // console.log('replacedName', replacedName)
      const selectedIndex = objectToArray(state.travelInfo?.package_by_dates[state?.selectedRecurringDate])
      .findIndex(item => item.name === replacedName);

      // const name = replacedName.charAt(0).toUpperCase() + replacedName.slice(1)
      const name = replacedName
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      // console.log(name)
      const cost = paramsPackageDetails.prices[selectedTripDate]

      // console.log(cost)
      // console.log(paramsPackageDetails.deposit)
      // console.log(1, name, cost, 1, cost, paramsPackageDetails.payment_due, paramsPackageDetails.deposit)
      if (cost && paramsPackageDetails.payment_due && paramsPackageDetails.deposit) {
        updatePackage(selectedIndex, name, cost.toString(), 1, cost, paramsPackageDetails.payment_due.toString(), paramsPackageDetails.deposit.toString());
        setDefaultPackageSelected(true)
      }
      if (!paramsPackageDetails.payment_due) {
        updatePackage(selectedIndex, name, cost.toString(), 1, cost, "90", paramsPackageDetails.deposit.toString());
        setDefaultPackageSelected(true)
      }
    }
  }, [state.travelInfo]);

  const removeThousandsSeparator = (str) => str.replace(/,/g, "");

  const handlePackageChange = (id, e) => {
    // console.log(id, e)
    const quantity = parseInt(e.target.value);
    const name = e.target.getAttribute("data-name");
    const cost = e.target.getAttribute("data-cost");
    const result = cost * quantity;
    // const id = id;
    const deposit = e.target.getAttribute("deposit");
    const payment_due = e.target.getAttribute("payment-due");
    // console.log('id', id)
    // console.log('state.packages?.find((pkge) => pkge.id === id)', state.packages?.find((pkge) => pkge.id === id))
    updatePackage(id, name, cost, quantity, result, payment_due, removeThousandsSeparator(deposit));
  };
  // console.log(state.packages?.find((pkge) => pkge.name === "Single rate")?.quantity || 0)

  const handleBuyerInputChange = (e) => {
    const { name, value } = e.target;
    updateBuyerInfo(name, value);
  };

  const handleOptionChange = (id, e) => {
    const quantity = parseInt(e.target.value);
    const name = e.target.getAttribute("data-name");
    const cost = e.target.getAttribute("data-cost");
    const result = cost * quantity;
    updateOptions(id, name, cost, quantity, result);
  };

  const handleCurrentStepChange = (step) => {
    updateCurrentStep(step);
  };

  function subtractDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
  }

  const formatedDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const totalQuantity = state.packages.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const totalPackagesResult = state.packages.reduce(
    (total, pkg) => total + pkg.result,
    0
  );
  const totalOptionsResult = state.options.reduce(
    (total, opt) => total + opt.result,
    0
  );
  let totalResult = totalPackagesResult + totalOptionsResult;

  if (state.promocode.percent_off !== null) {
    const discount = totalResult * (state.promocode.percent_off / 100);
    totalResult -= discount;
  }

  function formatDate(date) {
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  const weeks = getDatesWithSameDayOfWeek(subtractDays(new Date(state.selectedRecurringDate), (state.packages[0]?.payment_due ? state.packages[0]?.payment_due : 30)));
  const month = getDatesEvery15Days(subtractDays(new Date(state.selectedRecurringDate), (state.packages[0]?.payment_due ? state.packages[0]?.payment_due : 30)));

  const weekCost = Number(
    (totalResult - totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99)) / weeks.length
  ).toFixed(0);
  const monthCost = Number(
    (totalResult - totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99)) / month.length
  ).toFixed(0);

  const mergedCostsWeek = {};
  const mergedCostsMonth = {};

  // Add weekly costs
  weeks.forEach(dateString => {
    const date = new Date(dateString);
    const formattedDate = formatDate(date);
    mergedCostsWeek[formattedDate] = parseFloat(weekCost);
  });

  // Add monthly costs
  month.forEach(dateString => {
    const date = new Date(dateString);
    const formattedDate = formatDate(date);
    mergedCostsMonth[formattedDate] = parseFloat(monthCost);
  });




  // console.log('state.paymentDueoption', state.paymentDueoption)
  const fetchBookings = async () => {
    try {
      const bookingsData = await bookings({
        trip_id: state.travelInfo?.trip_id,
        trip_title: state.travelInfo?.name,
        booking_date: formatedDate(),
        booking_details:
          state.bookingTravelInfo[0]?.booking_details !== undefined
            ? state.bookingTravelInfo[0]?.booking_details
            : "",
        latest_availability_update: "2023-10-04",
        number_of_people: 2,
        release_date: state.travelInfo?.start_date[0],
        hotel_star_rating: 0,
        additional_options: transformOptionArray(state.options),
        packages: transformArray(state.packages),
        user_email: [state.buyerInfo.buyerEmail],
        payment_plan: state.paymentDueoption === "week" ? mergedCostsWeek : mergedCostsMonth,
        start_date:
          state.travelInfo?.trip_occurrence === "recurring"
            ? state.selectedRecurringDate
            : state.travelInfo?.start_date[0],
        availability: state.travelInfo?.availability,
        bed_type: 1
        // payment_plan:
        //   state.paymentDueoption === "week"
        //     ? {
        //         "2024-04-11": weekCost,
        //         "2024-04-18": weekCost,
        //         "2024-04-25": weekCost,
        //         "2024-05-02": weekCost,
        //         "2024-05-09": weekCost,
        //         "2024-05-16": weekCost,
        //         "2024-05-23": weekCost,
        //         "2024-05-30": weekCost,
        //         "2024-06-06": weekCost,
        //         "2024-06-13": weekCost,
        //         "2024-06-20": weekCost,
        //         "2024-06-27": weekCost,
        //         "2024-07-04": weekCost,
        //         "2024-07-11": weekCost,
        //         "2024-07-18": weekCost,
        //         "2024-07-25": weekCost,
        //         "2024-08-01": weekCost,
        //         "2024-08-08": weekCost,
        //         "2024-08-15": weekCost,
        //         "2024-08-22": weekCost,
        //         "2024-08-29": weekCost,
        //         "2024-09-05": weekCost,
        //         "2024-09-12": weekCost,
        //         "2024-09-19": weekCost,
        //         "2024-09-26": weekCost,
        //       }
        //     : {
        //         "15-04-2024": monthCost,
        //         "15-05-2024": monthCost,
        //         "15-06-2024": monthCost,
        //         "15-07-2024": monthCost,
        //         "15-08-2024": monthCost,
        //         "15-09-2024": monthCost,
        //       },
      });

      updateBookingCost(bookingsData.booking_response.cost);
      updateBookingID(bookingsData.booking_id);
      updateHotelInfo(bookingsData.hotel);
      return bookingsData;
    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };
  const handleContinue = () => {
    const hasSelectedPackage = state.packages.some((pkg) => pkg.quantity > 0);
    const isBuyerInfoValid =
      state.buyerInfo.buyerName !== "" &&
      state.buyerInfo.buyerLastName !== "" &&
      state.buyerInfo.buyerEmail !== "" &&
      state.buyerInfo.buyerEmail === state.buyerInfo.confirmEmail;
    setIsFieldsFull(isBuyerInfoValid);
    setIsPackageSelected(hasSelectedPackage);

    if (hasSelectedPackage && isBuyerInfoValid) {
      fetchBookings();
      handleCurrentStepChange(state.currentStep + 1);
    }
  };

  const generateSequence = (multiplier, pkg) => {
    if (pkg.number_of_custom_bookings) {
      const sequence = [];
      const package_name = pkg.name.split(" ").join("_").toLowerCase()

      if (state.travelInfo?.package && pkg.name &&  state.travelInfo?.package[package_name[0].toLowerCase() + package_name.slice(1)]?.remaining_spots){
        const remaining_spots = state.travelInfo?.package[package_name]?.remaining_spots
        for (let i = 1; i <= remaining_spots; i++) {

          if (i * multiplier <= remaining_spots) {
            sequence.push(i * multiplier);
          }
        }
        return sequence;
      }
      else {
        for (let i = 1; i <= pkg.number_of_custom_bookings; i++) {
          if (i * multiplier <= pkg.number_of_custom_bookings) {
            sequence.push(i * multiplier);
          }
        }
        return sequence;
      }
    }
    else {
      const quantity = state.hotelResources.filter((obj) =>
        pkg.hotel_resources.includes(obj.hotel_id)
      );

      const smallestAllocationObj = quantity.reduce((minObj, obj) => {
        return (Number(obj.allocation) < Number(minObj.allocation) ? obj : minObj);
      }, quantity[0]);

      // If you need just the smallest allocation value
      const sequence = [];
      if (smallestAllocationObj){
        const smallestAllocation = Number(smallestAllocationObj.allocation);
        for (let i = 1; i <= 50; i++) {
          if (i * multiplier <= smallestAllocation) {
            sequence.push(i * multiplier);
          }
        }
      }
      else {
        for (let i = 1; i <= 50; i++) {
          if (i * multiplier <= 20) {
            sequence.push(i * multiplier);
          }
        }
      }
      return sequence;
    }
  };

  const addThousandsSeparator = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <div className="package_form_wrapper">
      {state.travelInfo?.trip_occurrence === "recurring" && (
        <div className="section_wrapper">
          <div className="section_title">
            <span>Select Departure</span>
            <div
              className="package_select_wrapper"
              style={{ margin: "20px 0" }}
            >
              <select
                // value={state.selectedRecurringDate}
                defaultValue={selectedTripDate}
                onChange={(e) => updateTripDate(e.target.value)}
                // data-cost={
                //   pkg?.prices?.value !== undefined
                //     ? pkg?.prices?.value
                //     : pkg?.price?.value
                // }
                // data-name={pkg?.name}
                style={{ width: "100px" }}
              >
                <option>Select Date</option>
                {state.travelInfo?.start_date.map((value, i) => (
                  <option value={value} key={i}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="section_wrapper">
        <div className="section_title">
          <span>
            {state.travelInfo?.package.length === 1
              ? "Package"
              : "Select Package"}
          </span>
        </div>
        <div
          className={`${!isPackageSelected ? "warning" : "warning_default"}`}
        >
          {(state.travelInfo?.trip_occurrence === "recurring"
            ? state?.selectedRecurringDate !== null &&
              state.travelInfo?.package_by_dates != undefined
              ? state.travelInfo?.package_by_dates[
                  state?.selectedRecurringDate
                ] &&
                objectToArray(
                  state.travelInfo?.package_by_dates[
                    state?.selectedRecurringDate
                  ]
                )
              : []
            : state.travelInfo?.package_by_dates[
                state.travelInfo?.start_date[0]
              ] &&
              objectToArray(
                state.travelInfo?.package_by_dates[
                  state.travelInfo?.start_date[0]
                ]
              )
          )?.map((pkg, i) => {
            return (
              <div className="package_option_wrapper" key={i}>
                <div className="package_select_wrapper">
                  <img
                    alt="User icon"
                    src="https://cdn.wetravel.com/assets/icons/icon-user-grey.svg"
                  />
                  <select
                    defaultValue={
                      state.travelInfo?.package.length === 1 ? 1 : 0
                    }
                    value={
                      state.packages?.find((pkge) => pkge.name === pkg.name)
                        ?.quantity || 0
                    }
                    onChange={(e) => handlePackageChange(i, e)}
                    data-cost={
                      pkg?.prices?.value !== undefined
                        ? pkg?.prices?.value
                        : pkg?.price?.value
                    }
                    data-name={pkg?.name}
                    payment-due={pkg?.payment_due}
                    deposit={addThousandsSeparator(pkg?.deposit)}
                  >
                    <option value={0}>{0}</option>
                    {generateSequence(Number(pkg.person_per_booking), pkg).map(
                      (value, i) => (
                        <option value={value} key={i}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="package-header">
                  <span>{formatKey(pkg?.name)}</span>
                  {/* <p className="availability">Available until Jul 7, 2024</p> */}
                </div>
                <div className="package-price">
                  <p className="price">
                    $
                    {pkg?.prices?.value !== undefined
                      ? addThousandsSeparator(pkg?.prices?.value)
                      : addThousandsSeparator(pkg?.price?.value)}
                  </p>
                  {pkg.deposit !== null && (
                    <p className="deposit">Deposit: ${pkg.deposit}</p>
                  )}
                  {pkg.deposit !== null && (
                    <p className="deposit">Starting from: ${addThousandsSeparator(Number((pkg?.prices?.value - 1 * (pkg?.deposit ? pkg?.deposit : 99)) / weeks.length).toFixed(2))} weekly</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={`${
            !isPackageSelected ? "warning_text" : "warning_text_default"
          }`}
        >
          Please Select Your Package
        </div>
        {  totalQuantity > 1 && ( <div className="section_title" style={{ padding: "0" }}>
                    <span>Do you want split payment? </span>
                    <div
                      className="radio_inputs_wrapper"
                      style={{ margin: "13px 0", width: "inherit" }}
                    >
                      <input
                        type="radio"
                        id="required_yes"
                        value="yes"
                        checked={state.splitPayment === "yes"}
                        onChange={(e) => updateSplitStatus(e.target.value)}
                        className="positive"
                      />
                      <label htmlFor="required_yes">Yes</label>
                      <input
                        type="radio"
                        id="required_no"
                        value="no"
                        checked={state.splitPayment === "no"}
                        onChange={(e) => updateSplitStatus(e.target.value)}
                        className="negative"
                      />
                      <label htmlFor="required_no">No</label>
                    </div>
                  </div>)
                }
      </div>

      <div className="section_wrapper">
        {state?.travelInfo &&
          Object.keys(state?.travelInfo?.additional_options)?.length !== 0 && (
            <div className="section_title">
              <span>Select Options</span>
            </div>
          )}
        {state.travelInfo?.additional_options &&
          objectToArray(state.travelInfo?.additional_options).map((opt, i) => {
            return (
              <div className="package_option_wrapper" key={i}>
                <div className="package_select_wrapper">
                  <select
                    onChange={(e) => handleOptionChange(i, e)}
                    data-cost={opt.price !== undefined ? opt.price : opt.prices}
                    data-name={opt.name}
                  >
                    {Array.from(Array(25)).map((value, i) => (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="package-header">
                  <span>{formatKey(opt?.name)}</span>
                  {/* <p className="description">Day 2 in the Morning</p> */}
                </div>
                <div className="package-price">
                  <p className="price">
                    ${opt.price !== undefined ? addThousandsSeparator(opt.price) : addThousandsSeparator(opt.prices)}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="section_wrapper">
        <div className="section_title">
          <span>Payment Options</span>
          <PaymentOptions setSelectedPaymentMethod={setSelectedPaymentMethod} setIsBuyNowPayLaterSelected={setIsBuyNowPayLaterSelected} />
        </div>
      </div>
      <div className={`${!isFieldsFull ? "warning" : "warning_default"}`}>
        <div className="buyer_info_wrapper">
          <p className="title">Buyer Information</p>
          <div className="buyer_info_inputs_wrapper">
            <div className="group small_group">
              <input
                type="text"
                className="buyer_info_input"
                name="buyerName"
                value={state.buyerInfo.buyerName}
                onChange={(e) => handleBuyerInputChange(e)}
                required
              />
              <label htmlFor="buyerName">
                First Name <span>*</span>
              </label>
            </div>

            <div className="group small_group">
              <input
                type="text"
                className="buyer_info_input"
                name="buyerLastName"
                value={state.buyerInfo.buyerLastName}
                onChange={(e) => handleBuyerInputChange(e)}
                required
              />
              <label htmlFor="buyerLastName">
                Last Name <span>*</span>
              </label>
            </div>
            <div className="group small_group">
              <input
                type="email"
                className="buyer_info_input"
                name="buyerEmail"
                value={state.buyerInfo.buyerEmail}
                onChange={(e) => handleBuyerInputChange(e)}
                required
              />
              <label htmlFor="buyerEmail">
                Email <span>*</span>
              </label>
            </div>
            <div className="group small_group">
              <input
                type="email"
                name="confirmEmail"
                className="buyer_info_input"
                value={state.buyerInfo.confirmEmail}
                onChange={(e) => handleBuyerInputChange(e)}
                required
              />
              <label htmlFor="confirmEmail">
                Confirm Email <span>*</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${!isFieldsFull ? "warning_text" : "warning_text_default"}`}
      >
        Please Fill All Fields
      </div>
      <button className="continue_button" onClick={handleContinue}>
        Continue
      </button>
    </div>
  );
};
