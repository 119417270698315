import { useEffect } from "react";
import "./style.css";
import { useInfo } from "../../context";
import {
  bookings,
  getBookingTravelInfo,
  getBookings,
  getTravelInfo,
} from "../../services/bookingSevice";
import { formatDates } from "../../globals/formateDate";
import { useParams } from "react-router-dom";
import { checkStartDate } from "../../globals/checkDateType";
import {
  getAirlinesResources,
  getHotelResources,
} from "../../services/resourcesService";

export const ModalHeader = () => {
  const {
    state,
    updateCurrentStep,
    updateTravelInfo,
    updateBookingTravelInfo,
    updateParticipantField,
    updateBooking,
    updateHotelResources,
    updateFlightResources
  } = useInfo();

  const handleCurrentStepChange = (step) => {
    updateCurrentStep(step);
  };
  const steps = [
    "Choose a Package",
    "Add Participant Info",
    "Complete Payment",
  ];

  const { tripID, bookingId } = useParams();
  useEffect(() => {
    const fetchTravelInfo = async () => {
      try {
        const travelinfo = await getTravelInfo(tripID);
        updateTravelInfo(travelinfo);
        const transformFieldsToObject = () => {
          const customInfo = {};

          travelinfo.custom_client_fields.forEach((field) => {
            let value;

            switch (field.field_type) {
              case "checkboxes":
                value = [];
                break;
              case "multiple_choice":
                value = "";
                break;
              default:
                value = null;
            }

            customInfo[field.field_name] = {
              value: "",
              field_type: field.field_type,
            };
          });

          return customInfo;
        };
        updateParticipantField(transformFieldsToObject());
      } catch (error) {
        console.error("Failed to fetch travel info:", error);
      }
    };

    const fetchBookingInfo = async () => {
      try {
        const bookingsInfo = await getBookings(tripID);
        const booking = bookingsInfo.find(
          (bkg) => bkg.booking_id === bookingId
        );
        updateBooking(booking);
        // updateTravelInfo(travelinfo);
        // const transformFieldsToObject = () => {
        //   const customInfo = {};

        //   travelinfo.custom_client_fields.forEach((field) => {
        //     let value;

        //     switch (field.field_type) {
        //       case "checkboxes":
        //         value = [];
        //         break;
        //       case "multiple_choice":
        //         value = "";
        //         break;
        //       default:
        //         value = null;
        //     }

        //     customInfo[field.field_name] = {
        //       value:"",
        //       field_type: field.field_type,
        //     };
        //   });

        //   return  customInfo ;
        // };
        // updateParticipantField(transformFieldsToObject());
      } catch (error) {
        console.error("Failed to fetch travel info:", error);
      }
    };

    const fetchBookingTravelInfo = async () => {
      try {
        const bookingTravelinfo = await getBookingTravelInfo(tripID);
        updateBookingTravelInfo(bookingTravelinfo);
      } catch (error) {
        console.error("Failed to fetch travel info:", error);
      }
    };

    fetchTravelInfo();
    if (bookingId !== undefined) {
      fetchBookingInfo();
    }
    // fetchBookingTravelInfo();
    const fetchHotelResources = async () => {
      try {
        const hotelResouces = await getHotelResources();
        updateHotelResources(hotelResouces);
      } catch (error) {
        console.error("Failed to fetch travel info:", error);
      }
    };
    const fetchFlightResources = async () => {
      try {
        const flightResources = await getAirlinesResources();
        updateFlightResources(flightResources);
      } catch (error) {
        console.error("Failed to fetch travel info:", error);
      }
    };
    
    fetchHotelResources()
    fetchFlightResources();
  }, []);

  return (
    <div className="header_wrapper">
      <img
        src={state?.travelInfo?.image_paths[0]}
        alt="trip-img"
        className="trip_img"
      />
      <div className="travel_main_info_wrapper">
        <div className="travel_name">
          <a
            href="https://heritageafrica.wetravel.com/trips/cape-town-johannesburg-kruger-3-and-victoria-falls-h134b-heritage-africa-johannesburg-73720727"
            target="_blank"
            rel="noreferrer"
            className="c-text-[20px] c-font-medium c-leading-[33px] c-text-white c-text-center c-px-5"
          >
            {state.travelInfo?.name}
          </a>
        </div>
        {state.travelInfo?.trip_occurrence !== "recurring" && (
          <div className="travel_date">
            <img
              src="https://cdn.wetravel.com/assets/icons/icon-calendar-grey.svg"
              alt="calendar-icon"
              className="calendar_icon"
            />

            <span>
              {formatDates(
                checkStartDate(state.travelInfo?.start_date),
                state.travelInfo?.end_date
              )}
            </span>
          </div>
        )}
        {bookingId === undefined && (
          <div className="registration_steps_wrapper">
            {steps.map((step, i) => {
              return (
                <p
                  onClick={() => {
                    handleCurrentStepChange(i);
                  }}
                  key={i}
                >
                  {step} {i !== 2 && <span>{`>`}</span>}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
