import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import { InfoProvider } from "./context";
import { RegistrationModal } from "./views/RegistrationModal";
import { Trips } from "./views/Trips";
import { BookingConfirm } from "./views/BookingConfirm";
import { SplitPaymentModal } from "./views/SplitPaymentModal";
import { UpdateInformationModal } from "./views/UpdateInformationModal";

function App() {
  return (
    <InfoProvider>
      <div className="App">
        <div className="page_wrapper">
          <Router>
            <Routes>
              <Route path="/" element={<Trips />} />
              <Route path="/booking-trip/:tripID" element={<RegistrationModal />} />
              <Route path="/split-payment" element={<SplitPaymentModal />} />
              <Route path="/booking-confirm" element={<BookingConfirm />} />
              <Route path="/booking-trip/:tripID/booking-info/:bookingId/update-participant-info/:token/:role/:type" element={<UpdateInformationModal />} />
            </Routes>
          </Router>
        </div>
      </div>
    </InfoProvider>
  );
}

export default App;
