import { useInfo } from "../../context";
import { ParticipantInfoForm } from "../ParticipantInfoForm";
import { PaymentForm } from "../PaymentForm";
import { useParams } from 'react-router-dom';
import { UpdateInfoPaymentForm } from "../UpdateInfoPaymentForm";
import { UpdateParticipantInfoForm } from "../UpdateParticipantInfoForm";
import "./style.css";

export const UpdateInfoDataForm = ({type, newPrice, setNewPrice}) => {
  console.log("UpdateInfoDataForm", type, newPrice, setNewPrice)
  const { state } = useInfo();
  const { role, tripID, bookingId } = useParams();

  const form = () => {
    switch (state.currentStep) {
      case 0:
        return (
          <div>
            <UpdateParticipantInfoForm newPrice={newPrice} setNewPrice={setNewPrice} />
          </div>
        );
      case 1:
        if (role === 'admin') {

          window.location.href =   `https://admin.heritageafrica.com/trip-booking-page/${tripID}/booking-info/${bookingId}`;
          return null; // Return null to stop rendering the component
        }
        return (
          <div>
            <UpdateInfoPaymentForm />
          </div>
        );

      default:
        return <div></div>;
    }
  };
  return <div className="data_form_wrapper">{form()}</div>;
};
