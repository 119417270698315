const initializeGTM = () => {
  const dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "GTM-NRMVHFL");
};

export default initializeGTM;
