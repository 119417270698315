export const formatDates = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startMonth = startDate.toLocaleString("default", { month: "short" });
  const endMonth = endDate.toLocaleString("default", { month: "short" });

  return `${startMonth} ${startDate.getDate()} - ${endMonth} ${endDate.getDate()}, ${endDate.getFullYear()}`;
};
export const formatOneDate = (date) => {
  const today = new Date();
  const getDate = date ? new Date(date) : today;
  const startMonth = getDate.toLocaleString("default", { month: "short" });
  return `${startMonth} ${getDate.getDate()}, ${getDate.getFullYear()}`;
};
