import { useEffect, useState } from "react";
import "./style.css";
import { useInfo } from "../../context";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { checkoutSession, createSubscription } from "../../services/bookingSevice";
import CheckoutForm from "../CheckoutForm";
import { checkStartDate } from "../../globals/checkDateType";
import { formatOneDate } from "../../globals/formateDate";
import { useLocation, useSearchParams } from 'react-router-dom';

export const PaymentForm = ({selectedPaymentMethod, isBuyNowPayLaterSelected}) => {
  const { state, updateCurrentStep } = useInfo();
  const [clientSecret, setClientSecret] = useState();
  const [token, setToken] = useState("");
  const location = useLocation();

  useEffect(() => {
    // console.log(location)
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    setToken(token)
    fetchClientSecret(token)
  }, [location]);

  const stripePromise = loadStripe(
    "pk_live_lUSVarvS3pGTiFLo8sQe25Hp000fJIzNvD"
  );
  const totalQuantity = state.packages.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const totalPackagesResult = state.packages.reduce(
    (total, pkg) => total + pkg.result,
    0
  );
  const totalOptionsResult = state.options.reduce(
    (total, opt) => total + opt.result,
    0
  );
  let totalResult = totalPackagesResult + totalOptionsResult;

  if (state.promocode.percent_off !== null) {
    const discount = totalResult * (state.promocode.percent_off / 100);
    totalResult -= discount;
  }

  const packagesQuantity = state.packages
    .map((item) => {
      if (item.quantity) {
        return `${item.name} * ${item.quantity}`;
      } else {
        return item.name;
      }
    })
    .join(", ");

  const fetchClientSecret = async (token) => {
    // console.log(1)
    try {
      if (token) {
        // console.log(2)
        const checkoutBody = {
          email: state.buyerInfo.buyerEmail,
          cost:
            state.splitPayment === "yes"
              ? state.bookingCost / state.participantsInfo.length
              : state.bookingCost,
          metadata: {
            trip_id: state.travelInfo.trip_id,
            hotel_name: state.hotelInfo.hotel_name,
            number_of_people: `${totalQuantity}`,
            hotel_email: state.hotelInfo.hotel_email,
            client_id: state.clientID,
            booking_id: state.bookingID,
            full_name: `${state.buyerInfo.buyerName} ${state.buyerInfo.buyerLastName}`,
            user_email: state.buyerInfo.buyerEmail,
            confirmation_details: "",
            trip_name: state.travelInfo.name,
            package_name: packagesQuantity,
            price: `${totalResult}`,
            payment_plan:"",
            trip_date: `${checkStartDate(state.travelInfo?.start_date)},
            ${state.travelInfo?.end_date}`,
            today_date: formatOneDate(),
            hotel_id: state.hotelInfo.hotel_id,
            required_allocations: `${state.hotelInfo.required_allocations  || 1}`,
            remaining_fee: `${totalResult - (totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 69))}`,
            group_leader_token: token,
            number_of_packages: totalQuantity.toString()

          },
        }
        // console.log("fetching client secret")
        const checkoutSessionData = await checkoutSession(checkoutBody, selectedPaymentMethod);
        // console.log(checkoutSessionData)
        const client_secret = checkoutSessionData.clientSecret;

        setClientSecret(client_secret);

        return client_secret;
      }
      else {
        // console.log(3)
        const checkoutBody = {
          email: state.buyerInfo.buyerEmail,
          cost:
            state.splitPayment === "yes"
              ? state.bookingCost / state.participantsInfo.length
              : state.bookingCost,
          metadata: {
            trip_id: state.travelInfo.trip_id,
            hotel_name: state.hotelInfo.hotel_name,
            number_of_people: `${totalQuantity}`,
            hotel_email: state.hotelInfo.hotel_email,
            client_id: state.clientID,
            booking_id: state.bookingID,
            full_name: `${state.buyerInfo.buyerName} ${state.buyerInfo.buyerLastName}`,
            user_email: state.buyerInfo.buyerEmail,
            confirmation_details: "",
            period_duration: state.paymentDueOption,
            trip_name: state.travelInfo.name,
            package_name: packagesQuantity,
            price: `${totalResult}`,
            payment_plan:"",
            trip_date: `${checkStartDate(state.travelInfo?.start_date)},
            ${state.travelInfo?.end_date}`,
            today_date: formatOneDate(),
            hotel_id: state.hotelInfo.hotel_id,
            required_allocations: `${state.hotelInfo.required_allocations  || 1}`,
            remaining_fee: `${totalResult - (totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 69))}`

          },
        }
        // if (isBuyNowPayLaterSelected){
        //   console.log(1)
        // }
        // else {
          // console.log("fetching client secret")
          const checkoutSessionData = await checkoutSession(checkoutBody, selectedPaymentMethod);
          // console.log(checkoutSessionData)
          const client_secret = checkoutSessionData.clientSecret;

          setClientSecret(client_secret);
          return client_secret;
        // }
      }



    } catch (error) {
      console.error("Failed to fetch travel info:", error);
    }
  };

  // useEffect(() => {
  //   fetchClientSecret();
  // }, []);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
    clientSecret,
    appearance,
  };

  return (
    <div id="checkout">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            isBuyNowPayLaterSelected={isBuyNowPayLaterSelected}
            selectedPaymentMethod={selectedPaymentMethod}
            totalResult={totalResult}
            />
        </Elements>
      )}
    </div>
  );
};
