export const getDatesWithSameDayOfWeek = (futureDate) => {
  const today = new Date();
  const futureDayOfWeek = futureDate.getDay();
  const datesWithSameDayOfWeek = [];


  const futureDateMod = new Date(today);
  futureDateMod.setDate(today.getDate() + 7);

  // let currentDate = new Date(today);
  let currentDate = new Date(futureDateMod);
  while (currentDate <= futureDate) {
    if (currentDate.getDay() === futureDayOfWeek) {
      const formattedDate = currentDate.toISOString().split("T")[0];
      datesWithSameDayOfWeek.push(formattedDate);
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesWithSameDayOfWeek;
};

export const getDatesEvery15Days = (futureDate) => {
  const today = new Date();
  const datesEvery15Days = [];

  const futureDateMod = new Date(today);
  futureDateMod.setDate(today.getDate() + 30);

  // let currentDate = new Date(today);
  let currentDate = new Date(futureDateMod);

  // let currentDate = new Date(today);

  while (currentDate <= futureDate) {
    if (currentDate.getDate() === 15) {
      const formattedDate = currentDate.toISOString().split("T")[0];
      datesEvery15Days.push(formattedDate);
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesEvery15Days;
};
