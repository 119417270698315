import { useParams } from 'react-router-dom';
import { useInfo } from "../../context";
import "./style.css";

export const UpdateInfoCheckoutInfo = ({type, newPrice}) => {
  const { state } = useInfo();
  const { role } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const cost = urlParams.get("cost");
  return (
    <div className="checkout_part_wrapper">
      <div className="checkout_info_wrapper">
        <div className="underline">
          <span className="title">Your Booking</span>
        </div>
        {!type && (
          <div className="flex total_trip_price">
            <p className={`total`}>Update Info Total</p>
            <p className={`total_price`}>{role !== "admin" ? "$ " + newPrice : 0}</p>
          </div>
        )}
      </div>
    </div>
  );
};
