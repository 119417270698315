import React, { useState } from "react";
import { useInfo } from "../../context";
import {
  getDatesWithSameDayOfWeek,
  getDatesEvery15Days,
} from "../../globals/getDayOfWeekBeforeDate";
import { formatOneDate } from "../../globals/formateDate";

export const SplitPaymentOptions = ({setSelectedPaymentMethod, setIsBuyNowPayLaterSelected}) => {
  const { state, updatePaymentOption, updatePaymentDueOption } = useInfo();
  const [bankTransferSelected, setBankTransferSelected] = useState(false);
  const [cardSelected, setCardSelected] = useState(false);
  const [buyNowPayLaterSelected, setBuyNowPayLaterSelected] = useState(false);
  const [totalSelected, setTotalSelected] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);

  const [showRadioButtons, setShowRadioButtons] = useState(false);
  const cost = urlParams.get("cost");
  const tripDate = urlParams.get("trip_date");

  function subtractDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
  }

  const handleCheckboxChange = (e) => {
    setShowRadioButtons(e);
  }

  const handlePaymentOptionChange = (event) => {
    updatePaymentOption(event.target.value);
    setTotalSelected(!event.target.value)
  }

  const handleFullPaymentChange = (event) => {
    updatePaymentOption(!event.target.value);
    setTotalSelected(event.target.value)
  }
  const handlePaymentDueOptionChange = (event) => {
    updatePaymentDueOption(event.target.value);
  }
  const handleCardSelectedChange = (event) => {
    setSelectedPaymentMethod("card")
    updatePaymentOption("full");
    setCardSelected(!cardSelected)
    setBankTransferSelected(false)
    setBuyNowPayLaterSelected(false)
    handleCheckboxChange(!cardSelected)
  };

  const handleBankTransferSelectedChange = (event) => {
    setSelectedPaymentMethod("bank_transfer")
    updatePaymentOption("full");
    setBankTransferSelected(!bankTransferSelected)
    setCardSelected(false)
    setBuyNowPayLaterSelected(false)
    handleCheckboxChange(!bankTransferSelected)
  };

  const handleBuyNowPayLaterSelectedChange = (event) => {
    setSelectedPaymentMethod("buy_now_pay_later")
    if (!buyNowPayLaterSelected) {
      updatePaymentOption("due");
    }
    updatePaymentOption("full");
    setIsBuyNowPayLaterSelected(!buyNowPayLaterSelected);
    setBuyNowPayLaterSelected(!buyNowPayLaterSelected);
    setBankTransferSelected(false);
    setCardSelected(false);
  };

  const totalQuantity = 1

  const totalPackagesResult = cost
  const totalOptionsResult = 0
  let totalResult = Number(totalPackagesResult) + Number(totalOptionsResult);

  // if (state.promocode.percent_off !== null) {
  //   const discount = totalResult * (state.promocode.percent_off / 100);
  //   totalResult -= discount;
  // }
  console.log(tripDate.split(",")[0])
  //   const weeks = state.travelInfo?.start_date && getDatesWithSameDayOfWeek(new Date(`${state.travelInfo?.start_date}`));
  const weeks = getDatesWithSameDayOfWeek(subtractDays(new Date(tripDate.split(",")[0]), 90));
  //   const month = state.travelInfo?.start_date && getDatesEvery15Days(new Date(state.travelInfo?.start_date))
  // const month = getDatesEvery15Days(new Date(state.selectedRecurringDate));

  const month = getDatesEvery15Days(subtractDays(new Date(tripDate.split(",")[0]), 90));

  return (
    <fieldset>
    <div>
      <input
        type="checkbox"
        id="checkbox1"
        name="checkbox1"
        checked={buyNowPayLaterSelected}
        onChange={() => handleBuyNowPayLaterSelectedChange(!buyNowPayLaterSelected)}
      />

      <label htmlFor="checkbox1">
        Buy now pay later (Available in US)
        <br/>
        <img height="20" width="20" style={{ marginLeft: '20px' }} src="https://unpkg.com/simple-icons@v13/icons/klarna.svg" />
        <img height="20" width="20" style={{ marginLeft: '10px' }} src="https://unpkg.com/simple-icons@v13/icons/afterpay.svg" />
        <img height="20" width="24" style={{ marginLeft: '10px' }} src="https://pnghq.com/wp-content/uploads/affirm-logo-full-hd-png.png" />

      </label>
    </div>

    <div>
      <input
        type="checkbox"
        id="checkbox3"
        name="checkbox3"
        checked={cardSelected}
        onChange={() => handleCardSelectedChange(!cardSelected)}
      />
      <label htmlFor="checkbox3">
        Pay by card
        <br/>
        <img height="30" width="30" style={{ marginLeft: '20px' }} src="https://unpkg.com/simple-icons@v13/icons/visa.svg" />
        <img height="30" width="30" style={{ marginLeft: '20px' }} src="https://unpkg.com/simple-icons@v13/icons/mastercard.svg" />
        <img height="30" width="30" style={{ marginLeft: '20px' }} src="https://unpkg.com/simple-icons@v13/icons/americanexpress.svg" />
      </label>
    </div>

    <div>
      <input
        type="checkbox"
        id="checkbox2"
        name="checkbox2"
        checked={bankTransferSelected}
        onChange={() => handleBankTransferSelectedChange(!bankTransferSelected)}
      />
      <label htmlFor="checkbox2">
        Pay by bank transfer
        <br/>

      </label>
      <i className="fas fa-university" style={{ marginLeft: '10px', width: '20px', height: '20px', backgroundColor: 'red'}}></i>
    </div>

    {showRadioButtons && (
      <>
      <div>
        <div style={{ display: 'row' }}>
          <div>
            <input
              type="radio"
              id="full"
              name="paymentOption"
              value="full"
              checked={totalSelected}
              onChange={handleFullPaymentChange}
            />
            <label htmlFor="full">Pay full amount — ${totalResult}</label>
          </div>
          <div>
            <input
              type="radio"
              id="due"
              name="paymentOption"
              value="due"
              checked={state.paymentOption === "due"}
              onChange={handlePaymentOptionChange}
            />
            <label htmlFor="due">Pay with installments. Pay amount due — ${totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99)}</label>
          </div>
        </div>
        {state.paymentOption === "due" && (
          <div className="due_labels_wrapper">
            <label htmlFor="due">
              We will automatically charge your payment method on the dates below:
            </label>
            <fieldset>
              <div>
                <input
                  type="radio"
                  id="week"
                  name="paymentDueOption"
                  value="week"
                  checked={state.paymentDueOption === "week"}
                  onChange={handlePaymentDueOptionChange}
                />
                <label htmlFor="week">Pay By Week</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="month"
                  name="paymentDueOption"
                  value="month"
                  checked={state.paymentDueOption === "month"}
                  onChange={handlePaymentDueOptionChange}
                />
                <label htmlFor="month">Pay by month</label>
                {state.paymentDueOption === "month" && (
                  <div className="due_options_labels_wrapper">
                    <label htmlFor="month">
                      ${totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99))} — Amount due at booking
                      {month.map((date, i) => (
                        <React.Fragment key={i}>
                          <br />
                          <label htmlFor="week">
                            $
                            {Number((totalResult - totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99)) / month.length).toFixed(2)} - {i + 1} payment due {formatOneDate(date)}
                          </label>
                        </React.Fragment>
                      ))}
                    </label>
                  </div>
                )}
                {state.paymentDueOption === "week" && (
                  <div className="due_option_labels_wrapper">
                    <label htmlFor="week">
                      ${totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99)} — Amount due at booking
                      <br />
                      {weeks.map((date, i) => (
                        <React.Fragment key={i}>
                          <label htmlFor="week">
                            $
                            {Number((totalResult - totalQuantity * (state.packages[0]?.deposit ? state.packages[0]?.deposit : 99)) / weeks.length).toFixed(2)} - {i + 1} payment due {formatOneDate(date)}
                          </label>
                          <br />
                        </React.Fragment>
                      ))}
                    </label>
                  </div>
                )}
              </div>
            </fieldset>
            <br />
          </div>
        )}
      </div>

    {/*<div>
      <input
        type="radio"
        id="full"
        name="paymentOption"
        value="full"
        checked={state.paymentOption === "full"}
        onChange={handlePaymentOptionChange}
      />
      <label htmlFor="full">Pay full amount — ${totalResult}</label>
    </div> */}
    </>
  )}

{/*   <div>
    <input
      type="checkbox"
      id="checkbox2"
      name="checkbox2"
      checked={showRadioButtons}
      onChange={handleCheckboxChange}
    />
    <label htmlFor="checkbox2">Pay by bank transfer or wire</label>
  </div> */}




    </fieldset>
    );
};
