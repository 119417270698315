import { useEffect } from "react";
import "./style.css";
import { useInfo } from "../../context";

export const SplitPaymentModalHeader = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tripId = urlParams.get("trip_id");
  const price = urlParams.get("price");
  const bookingId = urlParams.get("booking_id");
  const packageName = urlParams.get("package_name");
  const tripDate = urlParams.get("trip_date");
  const fullName = urlParams.get("full_name");
  const hotelName = urlParams.get("hotel_name");
  const userEmail = urlParams.get("user_email");
  const hotelEmail = urlParams.get("hotel_email");
  const todayDate = urlParams.get("today_date");
  const numberOfPeople = urlParams.get("number_of_people");
  const tripName = urlParams.get("trip_name");
  const paymentPlan = urlParams.get("payment_plan");
  const confirmationDetails = urlParams.get("confirmation_details");

  return (
    <div className="header_wrapper">
      <img
        src="https://process.filestackapi.com/resize=fit:crop,width:556,height:385/quality=v:79/compress/cache=expiry:604800/SmE05NjiTxOIeKyfcZWQ"
        alt="trip-img"
        className="trip_img"
      />
      <div className="travel_main_info_wrapper">
        <div className="travel_name">
          <a
            href="https://heritageafrica.wetravel.com/trips/cape-town-johannesburg-kruger-3-and-victoria-falls-h134b-heritage-africa-johannesburg-73720727"
            target="_blank"
            rel="noreferrer"
            className="c-text-[20px] c-font-medium c-leading-[33px] c-text-white c-text-center c-px-5"
          >
            {tripName}
          </a>
        </div>
        <div className="travel_date">
          <img
            src="https://cdn.wetravel.com/assets/icons/icon-calendar-grey.svg"
            alt="calendar-icon"
            className="calendar_icon"
          />
          <span>{tripDate}</span>
        </div>
      </div>
    </div>
  );
};
