import { useEffect, useRef, useState } from "react";

export const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  const [displayText, setDisplayText] = useState(text.slice(0, 200)); // Default to 200 characters
  const maxCharacters = 200;

  const textRef = useRef(null);

  useEffect(() => {
    const updateText = () => {
      setDisplayText(expanded ? text : text.slice(0, maxCharacters));
    };

    updateText(); // Initial update
  }, [text, expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="participant_question_wrapper">
      <p
        className="participant_info_descriptions"
        style={{ whiteSpace: "pre-line" }}
        ref={textRef}
      >
        {displayText}
        <span>*</span>
      </p>
      {text.length > maxCharacters && (
        <button onClick={toggleExpanded}>
          {expanded ? "Show less" : "Read more"}
        </button>
      )}
    </div>
  );
};
