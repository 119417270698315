import { useInfo } from "../../context";
import "./style.css";

export const SplitPaymentCheckoutInfo = () => {
  const { state } = useInfo();
  const urlParams = new URLSearchParams(window.location.search);
  const cost = urlParams.get("cost");
  return (
    <div className="checkout_part_wrapper">
      <div className="checkout_info_wrapper">
        <div className="underline">
          <span className="title">Your Booking</span>
        </div>
        <div className="flex total_trip_price">
          <p className={`total`}>Trip Total</p>
          <p className={`total_price`}>$ {cost}</p>
        </div>
      </div>
    </div>
  );
};
