import { useState } from "react";
import "./style.css";
import { SplitPaymentParticipantInfo } from "../SplitPaymentParticipantInfo";
import { useInfo } from "../../context";
import { SplitPaymentForm } from "../SplitPaymentForm";

export const SplitPaymentDataForm = () => {
  const { state } = useInfo();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("card");
  const [isBuyNowPayLaterSelected, setIsBuyNowPayLaterSelected] = useState(false);
  const form = () => {
    switch (state.currentStep) {
      case 0:
        return (
          <div>
            <SplitPaymentParticipantInfo
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              setIsBuyNowPayLaterSelected={setIsBuyNowPayLaterSelected}
            />
          </div>
        );
      case 1:
        return (
          <div>
            <SplitPaymentForm
              selectedPaymentMethod={selectedPaymentMethod}
              isBuyNowPayLaterSelected={isBuyNowPayLaterSelected}
            />
          </div>
        );

      default:
        return <div></div>;
    }
  };
  return <div className="data_form_wrapper">{form()}</div>;
};
